import { PUBLIC_ENV_KEY } from 'next-runtime-env';

import { isServerSide } from '@/helpers/isServerSide';

const buildEnv = <T extends readonly string[]>(keys: T) =>
  Object.fromEntries(keys.map((key) => [key, process.env[key]])) as Record<(typeof keys)[number], string>;

const publicEnvKeys = [
  'APP_NAME',
  'APP_VERSION',
  'NODE_ENV',
  'PROTOCOL',
  'ORIGIN',
  'LOGGING_LEVEL',

  'CONTEXT',
  'PAGE_404',

  'ASSET_PREFIX',
  'BASE_URL',
  'GRAPHQL_URL',
  'DXP_CORE_BASE_URL',
  'VV_BASE_URL',
  'PAYMENT_URL',
  'SAILOR_API_BASE_URL',
  'SEAWARE_EXTENSION_BASE_URL',
  'CLIENT_ID',
  'CLIENT_SECRET',

  'AUTH_BASE_URL',
  'AUTH_IFRAME_URL',
  'CHATBOT_URL',

  'CMS_AUTH_BASE_URL',
  'CMS_BASE_URL',
  'CMS_COMPONENT_BASE_URL',
  'CMS_COMPONENT_BASE_URL_BOOK',

  'GTM_ID',
  'STORYLYTOKEN',
] as const;

export const publicEnv = buildEnv(publicEnvKeys);

const serverEnvKeys = [
  'CMS_REST_PASSWORD',
  'CMS_REST_USERNAME',
  'CONFIG_BASIC_TOKEN', // get rid after https://virginvoyages.atlassian.net/browse/MSH-108420
] as const;

const serverEnv = buildEnv(serverEnvKeys);

export const env = new Proxy(
  {
    ...publicEnv,
    ...serverEnv,
  },
  {
    get(target, key) {
      if (isServerSide() || target.NODE_ENV === 'test') {
        return target[key as keyof typeof target];
      }
      return window[PUBLIC_ENV_KEY][key as string];
    },
  },
);
