import Link from 'next/link';
import { useEffect, useState } from 'react';

import Button from '../elements/Button';

import classes from './RootError.module.scss';

const contactUsLinkMap = {
  cert: 'https://virginvoyages--qa.sandbox.my.site.com/helpportal/s/contactus',
  dev: 'https://virginvoyages--dev.sandbox.my.site.com/helpportal/s/contactus',
  int: 'https://int.gcpshore.virginvoyages.com/help/contactus',
  localhost: 'https://virginvoyages--dev.sandbox.my.site.com/helpportal/s/contactus',
  stage: 'https://virginvoyages--stage.sandbox.my.site.com/helpportal/s/contactus',
  virginvoyages: 'https://help.virginvoyages.com/helpportal/s/contactus',
  www: 'https://help.virginvoyages.com/helpportal/s/contactus',
};

type Props = {
  error: { digest?: string } & Error;
  reset: () => void;
};

export const RootError = ({ error, reset }: Props) => {
  const [link, setLink] = useState('');
  useEffect(() => {
    const hostname = window.location.hostname ?? '';
    const key = (hostname.split('.')[0] ?? 'www') as keyof typeof contactUsLinkMap;
    setLink(contactUsLinkMap[key]);
  }, []);

  console.error('Root Error Stack::::::::::::::::::', error?.stack);

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <h1>It&apos;s not you — it&apos;s us</h1>
        We&apos;re making some updates to ensure smooth sailing.
        <br />
        <br />
        Visit our homepage and try again,
        <br />
        or&nbsp;
        <Link href={link} prefetch={false}>
          contact Sailor Services
        </Link>
        <br />
        who can steer you in the right direction.
      </div>
      <a href="/" onClick={reset}>
        <Button className={classes.homeButton} variant="primary">
          Take me home
        </Button>
      </a>
    </div>
  );
};
